import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';
import CTAComponent from '../components/cta';
import ProductsHighlight from '../components/productsHighlight';
import { Wave1 } from '../components/wave';
import Article from '../components/article';

const Container = styled.div`
    display: block;
    background-size: 100%;
    background: #fafafa;
    padding-top: 4px;
    padding-bottom: 60px;
    @media screen and (max-width: 767px) {
        padding-bottom: 90px;
    }
`;
const DesktopContainer = styled.div`
    margin: 0;
    display: flex;

    margin-left: 64px;
    margin-right: 64px;
    width: calc(100% - 128px);

    color: white;
    text-align: center;
    flex-direction: ${props =>
        props.direction === 'right' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const MobileContainer = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    z-index: 0;
    margin-top: 0;
    @media screen and (max-width: 768px) {
        margin-top: 32px;
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    text-align: left;
    color: #19171a;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    margin-top: 18px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: left;
    }
`;

const Desc = styled.div`
    margin-top: 34px;
    font-size: 17px;
    font-family: 'Neuzeit Grotesk';
    text-align: left;
    letter-spacing: normal;
    line-height: 24px;
    color: rgba(25, 23, 26, 0.9);
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 768px) {
        font-size: 17px;
        font-family: 'Neuzeit Grotesk';
        text-align: left;
        letter-spacing: normal;
        line-height: 24px;
    }
`;
const ImgContainer = styled.div`
    width: 40%;

    & > * {
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-top: 24px;
        & > * {
        }
    }
`;
const Main = styled.div`
    margin-top: 80px;
    @media screen and (max-width: 767px) {
        margin-top: 40px;
        margin-bottom: 60px;
    }
`;

const Wave32 = styled.div`
    height: 100px;
    & > * {
        margin-top: -60px;
        width: 100%;
        position: absolute;
        @media screen and (max-width: 767px) {
            margin-top: 0;
            position: relative;
        }
    }
    @media screen and (max-width: 767px) {
        height: auto;
    }
`;
export default function HadronIdPage({ data }) {
    const {
        seo_content,
        hero,
        hadron_intro,
        highlights,
        articles,
        book_now,
    } = data.butterPage;
    return (
        <Layout menu="FOR PUBLISHERS">
            <SEOComponent
                title="Hadron ID™"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4 {...hero[0]} type={1}></Hero4>
            <Container>
                <DesktopContainer>
                    <ImgContainer>
                        <img
                            src={hadron_intro.image}
                            width="100%"
                            alt={hadron_intro.title}
                            style={{ objectFit: 'contain' }}
                        ></img>
                    </ImgContainer>
                    <Content>
                        <div>
                            <Heading>{hadron_intro.title}</Heading>
                            <Desc>{hadron_intro.description}</Desc>
                        </div>
                    </Content>
                </DesktopContainer>
                <MobileContainer>
                    <Heading>{hadron_intro.title}</Heading>
                    <ImgContainer>
                        <img
                            src={hadron_intro.image}
                            width="100%"
                            alt={hadron_intro.title}
                        ></img>
                    </ImgContainer>
                    <Desc>{hadron_intro.description}</Desc>
                </MobileContainer>
            </Container>
            <Main>
                <Article
                    {...articles[0]}
                    direction={'right'}
                    spacing={0}
                ></Article>
                <Wave32>
                    <Wave1 />
                </Wave32>

                <ProductsHighlight {...highlights[0]} />
            </Main>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const hadronPageQuery = graphql`
    query {
        butterPage(slug: { eq: "hadron-id" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                image
                description
                desktop_image
                mobile_image
                mask_image
                buttons {
                    type
                    title
                    theme
                    url
                }
            }
            hadron_intro {
                title
                description
                image
            }

            articles {
                title
                description
                image
            }
            highlights {
                title
                description
                features {
                    title
                    description
                    image
                }
            }

            book_now {
                button_title
                title
            }
        }
    }
`;
